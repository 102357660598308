import MapNavButton from '@locus-labs/mol-desktop-map-nav-button'
import Tooltip from '@locus-labs/mol-desktop-tooltip'
import React, { useState, useEffect, useRef } from 'react'
import styled, { withTheme } from 'styled-components'

import ToolTipComponent from '../../../../src/ui/tooltips/ToolTipComponent.jsx'
import useComponentFocused from '../../../../src/utils/hooks/useComponentFocused.js'
import { getThemeColor, getThemeVal } from '../../../../src/utils/styled-component-tools'

const StyledMapNavButton = styled(MapNavButton)`
  & svg {
    fill: ${getThemeVal('colors.background', '#FFFFFF')};
  }
  &.showPanControls {
    opacity: 1;
  }
`
const Anchor = styled.div`
  position: relative;
`
const PanControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 36px;
  bottom: -56px;
  background-color: ${getThemeColor('background', '#FFFFFF', 0.5)};
  border: 1px solid ${getThemeVal('colors.primaryText', '#333333')};
  border-radius: ${getThemeVal('radii.md', '4px')};
  padding: 8px;
`
const TopWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`
const BottomWrapper = styled.div`
  display: flex;
`
const Space = styled.div`
  width: 10px;
`

const PanTooltip = styled(Tooltip)`
  &:first-child {
    border: 1px solid red;
    margin-left: 30px;
    background-color: green !important;
  }
`

const MapPanControlsDesktop = withTheme(({ theme, bus, T, delta = 150 }) => {
  const [showPanControls, setShowPanControls] = useState(false)
  const [treatAsClick, setTreatAsClick] = useState(true)

  const {
    ref: componentFocusedRef,
    isComponentFocused,
    setIsComponentFocused
  } = useComponentFocused(true)

  const panControlsWrapperRef = useRef()

  useEffect(() => {
    if (!isComponentFocused && showPanControls) {
      setShowPanControls(false)
    }; // if the outside click was detected, but menu was open, hide it
  }, [isComponentFocused])

  const onPanTriggerClick = async () => {
    setShowPanControls((prev) => !prev)
    setIsComponentFocused(!showPanControls)
  }

  const onPanTriggerKeydown = (e) => {
    if (e.shiftKey && e.key === 'Tab') {
      setShowPanControls(false)
    }
  }

  const mapMoveAction = (move) => {
    switch (move) {
      case 'panUp':
        bus.send('map/pan', { offset: [0, -1 * delta] })
        bus.send('appInsights/log', { name: `mapPan click`, properties: { direction: 'panUp' } })
        break
      case 'panDown':
        bus.send('map/pan', { offset: [0, delta] })
        bus.send('appInsights/log', { name: `mapPan click`, properties: { direction: 'panDown' } })
        break
      case 'panLeft':
        bus.send('map/pan', { offset: [-1 * delta, 0] })
        bus.send('appInsights/log', { name: `mapPan click`, properties: { direction: 'panLeft' } })
        break
      case 'panRight':
        bus.send('map/pan', { offset: [delta, 0] })
        bus.send('appInsights/log', { name: `mapPan click`, properties: { direction: 'panRight' } })
    }
  }

  const panButtonOnClick = (move) => {
    if (treatAsClick) {
      mapMoveAction(move)
    }
  }

  const intervalIdRef = useRef(null)

  const handleMouseDown = (move) => {
    setTreatAsClick(false)
    intervalIdRef.current = setInterval(() => {
      mapMoveAction(move)
    }, 50)
  }

  const handleMouseUp = () => {
    setTreatAsClick(true)
    clearInterval(intervalIdRef.current)
    intervalIdRef.current = null
  }

  useEffect(() => {
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current)
      }
    }
  }, [])

  const triggerRef = useRef(null)
  const firstControlRef = useRef(null)
  const lastControlRef = useRef(null)

  const wrapperKeyDownHandler = e => {
    if (e.key === 'Escape') {
      setShowPanControls(false)
      setIsComponentFocused(false)
      triggerRef.current.focus()
    }
    if (e.key === 'Tab' && !e.shiftKey) {
      if (document.activeElement === lastControlRef.current) {
        setShowPanControls(false)
      }
    }
  }

  return (
    <Tooltip
      content={T('mapPanControls:Move Map')}
      cancelTooltip={showPanControls}
      data-cy='MapPanTooltip'
    >
      <Anchor ref={componentFocusedRef} tabIndex={-1}>
        <StyledMapNavButton onKeyDown={(e) => onPanTriggerKeydown(e)} ref={triggerRef} data-cy="MapPanTrigger" iconName="move.note" active={showPanControls} iconColor={showPanControls ? theme.colors.primaryButtonText : theme.colors.secondaryText} onClick={onPanTriggerClick} ariaLabel={T('mapPanControls:Show Map Controls')} aria-pressed={showPanControls} aria-controls={'mapPanControls'} aria-expanded={showPanControls} className={showPanControls ? 'showPanControls' : ''}/>
        { showPanControls &&
        <PanControlsWrapper role='dialog' aria-modal='false' aria-label={`${T('mapPanControls:Move Map')} dialog`} id={'mapPanControls'} data-cy='MapPanControls' ref={panControlsWrapperRef} onKeyDown={wrapperKeyDownHandler} >
          <TopWrapper>
            <ToolTipComponent
              isVisible='false'
              arrowPosition={'right'}
              tooltipText={T('mapPanControls:Pan Map Up')}
            >
              <StyledMapNavButton ref={firstControlRef} data-cy="desktopPanUp" iconName="arrow.down" degreesOfIconRotation={180} ariaLabel={T('mapPanControls:Pan Map Up')} onClick={() => panButtonOnClick('panUp')} onMouseDown={() => handleMouseDown('panUp')} onMouseUp={handleMouseUp} onMouseOut={handleMouseUp}/>
            </ToolTipComponent>
          </TopWrapper>
          <BottomWrapper>
            <ToolTipComponent
              isVisible='false'
              arrowPosition={'top'}
              tooltipText={T('mapPanControls:Pan Map Left')}
            >
              <StyledMapNavButton data-cy="desktopPanLeft" iconName="arrow.down" degreesOfIconRotation={90} ariaLabel={T('mapPanControls:Pan Map Left')} onClick={() => panButtonOnClick('panLeft')} onMouseDown={() => handleMouseDown('panLeft')} onMouseUp={handleMouseUp} onMouseOut={handleMouseUp}/>
            </ToolTipComponent>
            <Space />
            <ToolTipComponent
              isVisible='false'
              arrowPosition={'top'}
              tooltipText={T('mapPanControls:Pan Map Down')}
            >
              <StyledMapNavButton data-cy="desktopPanDown" iconName="arrow.down" degreesOfIconRotation={0} ariaLabel={T('mapPanControls:Pan Map Down')} onClick={() => panButtonOnClick('panDown')} onMouseDown={() => handleMouseDown('panDown')} onMouseUp={handleMouseUp} onMouseOut={handleMouseUp}/>
            </ToolTipComponent>
            <Space />
            <ToolTipComponent
              isVisible='false'
              arrowPosition={'top'}
              tooltipText={T('mapPanControls:Pan Map Right')}
            >
              <StyledMapNavButton ref={lastControlRef} data-cy="desktopPanRight" iconName="arrow.down" degreesOfIconRotation={270} ariaLabel={T('mapPanControls:Pan Map Right')} onClick={() => panButtonOnClick('panRight')} onMouseDown={() => handleMouseDown('panRight')} onMouseUp={handleMouseUp} onMouseOut={handleMouseUp}/>
            </ToolTipComponent>
          </BottomWrapper>
        </PanControlsWrapper>}
      </Anchor>
    </Tooltip>

  )
})

export default MapPanControlsDesktop
